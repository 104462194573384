'use client';

import { Button, ButtonProps, LinkProps } from '@chakra-ui/react';
import { Route } from 'next';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import React, { ReactElement } from 'react';

export interface NextLinkButtonProps<THref extends string> extends ButtonProps {
   href: Route<THref>;
   target?: LinkProps['target'];
   icon?: ReactElement;
   children?: string | React.ReactNode;
   onClick?: () => Promise<void> | void;
}

export function NextLinkButton<T extends string>({
   icon: Icon,
   target = '_self',
   href,
   onClick,
   ...rest
}: NextLinkButtonProps<T>) {
   const { push } = useRouter();
   const [isOnClickLoading, setIsOnClickLoading] = React.useState(false);

   const handleClick = async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
   ) => {
      if (onClick) {
         const res = onClick();
         if (res instanceof Promise) {
            e.preventDefault();
            setIsOnClickLoading(true);

            await res;

            setIsOnClickLoading(false);

            if (href) {
               push(href);
            }
         }
      }
   };

   return rest.disabled ||
      rest.isDisabled ||
      isOnClickLoading ||
      rest.isLoading ? (
      <Button
         colorScheme="buttonPrimary"
         size="md"
         {...rest}
         rightIcon={Icon}
         disabled
      />
   ) : (
      <NextLink href={href} passHref target={target} legacyBehavior>
         <Button
            colorScheme="buttonPrimary"
            as="a"
            size="md"
            target={target === '_self' ? undefined : target}
            isLoading={isOnClickLoading}
            {...rest}
            onClick={handleClick}
            rightIcon={Icon}
         />
      </NextLink>
   );
}
